import styled from 'styled-components';
import { color } from '../../config';

export const BuildWorkflowHeader = styled.div`
  position: relative;
  z-index: 10;
  padding: 1.5rem 3rem;
  background-color: white;
  box-shadow: 0px 4px 4px 0 rgba(44, 51, 63, 0.1);
  display: flex;
  align-items: center;
  h2 {
    margin-right: auto;
  }
  button {
    margin-left: 2rem;
  }
`;

export const NewFormFieldNotice = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 3rem;
  font-size: 1.4rem;
  color: ${color.grey.base};
`;
