import React, { useCallback } from 'react';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import {
  TWorkflowStageSpec,
  blankStages,
} from '../../../context-providers/forms/form-workflows';
import {
  useNewWorkflow,
  useSetNewWorkflow,
} from '../../../context-providers/forms/forms-hooks';
import { IWorkflowStage } from '../../../context-providers/forms/forms-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import ReactGA from '../../../utils/google-analytics';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import Icon from '../../icons/Icon';
import { FormWorkflowButtons } from './CreateWorkflow.style';
import AddStage from '../../modals/forms/AddStage';

const CreateWorkflow = () => {
  const newWorkflow = useNewWorkflow();
  const showModal = useShowModal();
  const nameError = validateField(newWorkflow.workflowName, { required: true });
  const setNewWorkflow = useSetNewWorkflow();

  const addStage = useCallback(
    ({ stageId, editable }: TWorkflowStageSpec) => {
      if (!editable) {
        setNewWorkflow({
          ...newWorkflow,
          stages: [
            ...newWorkflow.stages,
            {
              stageId,
            },
          ],
        });
        return;
      }
      showModal(AddStage, {
        stage: { stageId },
        onComplete: (stage: IWorkflowStage) => {
          setNewWorkflow({
            ...newWorkflow,
            stages: [...newWorkflow.stages, stage],
          });
        },
      });
    },
    [newWorkflow],
  );

  const setWorkflowName = (workflowName: string) => {
    setNewWorkflow({
      ...newWorkflow,
      workflowName,
    });
  };

  return (
    <Stack stackMultiplier={2}>
      <Stack stackMultiplier={1}>
        <TextInput
          maxLength={MaxLength.WorkflowName}
          label="Name Workflow"
          value={newWorkflow.workflowName}
          id="formWorkflowName"
          dataQa="input-form-workflow-name"
          error={nameError}
          placeholder="Workflow Name *"
          onChangeText={(str) => setWorkflowName(str)}
        />
      </Stack>
      <Stack stackMultiplier={1}>
        <h3>Add Stage</h3>
        <p>
          Select the party completing the form at each stage of the workflow.
        </p>
        <FormWorkflowButtons>
          {blankStages
            .filter((item) => !item.hidden)
            .map((item, i) => {
              return (
                <button
                  type="button"
                  data-qa={`form-btn-${item.type}`}
                  key={`${item.type}-${i}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Button',
                      action: 'Build form - delete stage',
                    });
                    addStage(item);
                  }}
                >
                  <Icon type={item.icon} />
                  <span>{item.label}</span>
                </button>
              );
            })}
        </FormWorkflowButtons>
      </Stack>
    </Stack>
  );
};

export default CreateWorkflow;
