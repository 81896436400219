import React from 'react';
import { useNewWorkflow } from '../../../context-providers/forms/forms-hooks';
import Stack from '../../atoms/stack/Stack';
import { LightContentBox } from '../../layout/Layout.style';
import { NewFormFieldNotice } from './BuildWorkflow.style';
import BuildWorkflowItem from './BuildWorkflowItem';

const BuildWorkflowContent = () => {
  const newFormWorkflow = useNewWorkflow();
  const stages = newFormWorkflow.stages;
  const showNoStages = () => {
    return stages.length < 1 ? (
      <NewFormFieldNotice>
        New form workflow stages will be placed here
      </NewFormFieldNotice>
    ) : null;
  };

  return (
    <Stack stackMultiplier={2}>
      <LightContentBox>
        <Stack stackMultiplier={1}>
          {showNoStages()}
          {stages.map((stage, i) => (
            <BuildWorkflowItem
              key={`stage-` + i}
              index={i}
              stage={stage}
            ></BuildWorkflowItem>
          ))}
        </Stack>
      </LightContentBox>
    </Stack>
  );
};

export default BuildWorkflowContent;
