import styled from 'styled-components';
import { color } from '../../config';

export const FormWorkflowButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  button {
    display: block;
    width: calc(50% - 2.01rem); /* 2.01 because edge has wacky rounding */
    margin: 0 1rem 2rem;
    background-color: ${color.mist.base};
    border-radius: 8px;
    border: none;
    padding: 1.3rem 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    &:hover:not(:disabled) {
      background-color: ${color.primary.base};
      color: white;

      svg {
        color: white;
      }
    }

    &:focus {
      outline-offset: 4px;
    }

    :not(:disabled) svg {
      color: ${color.primary.base};
    }

    span {
      display: block;
      text-align: center;
      margin-top: 0.8rem;
    }
  }
`;
