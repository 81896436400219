import styled, { css } from 'styled-components';
import { boxShadow, color } from '../../../config';

interface IInputCheckbox {
  invalid: boolean;
  disabled: boolean;
}

export const InputCheckboxField = styled.div`
  position: relative;
  display: inline-flex;
`;

export const CheckboxInline = styled.div`
  display: flex;
  align-items: flex-start;

  label {
    flex: 1;
    margin-left: 2rem;
    line-height: 1.5;
  }

  label.truncate {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const InputCheckbox = styled.input<IInputCheckbox>`
  appearance: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 2rem;
  color: ${color.grey.dark};
  border-radius: 0.25em;
  border: ${(props) =>
    props.invalid
      ? `2px solid ${color.error.foreground}`
      : `2px solid ${color.grey.light}`};
  opacity: ${(props) => (props.disabled ? `0.8` : undefined)};
  transition: all 0.15s linear;

  :checked {
    background-color: ${color.primary.base};
    border-color: ${color.primary.base};

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -62%) rotate(45deg);
      height: 1.1rem;
      width: 0.6rem;
      border-bottom: 0.2rem solid ${color.light};
      border-right: 0.2rem solid ${color.light};
    }

    :focus {
      border-color: ${color.primary.base};
    }
  }

  :focus {
    outline: none;
    border-color: ${color.grey.base};
    box-shadow: ${(props) =>
      props.invalid ? boxShadow.error : boxShadow.primary};
  }

  :disabled {
    ${(props) => {
      if (!props.readOnly) {
        return InputCheckboxDisabled;
      }
      return null;
    }}
  }
`;

const InputCheckboxDisabled = css`
  background-color: ${color.grey.lightest};
  border-width: 1px;
`;
