import React, { useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  useClearNewWorkflow,
  useNewWorkflow,
} from '../../../context-providers/forms/forms-hooks';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import { IconArrowRight, IconAssessments } from '../../icons';
import { BuildWorkflowHeader } from './BuildWorkflow.style';
import CreateWorkflow from './CreateWorkflow';
import BuildWorkflowContent from './BuildWorkflowContent';
import { LayoutContent, LayoutSidebar } from '../../layout/Layout.style';
import { navigate } from 'gatsby';
import { fetchAPI } from '../../../utils/fetch-api';
import { StageId } from '../../../context-providers/forms/form-workflows';
import Message from '../../atoms/message/Message';
import { Box } from '../../atoms/custom-elements/Box.style';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import { daysToTimespan } from '../../../utils/dates';
import SaveAndExit from '../../modals/forms/SaveAndExit';

const isBrowser = typeof window !== 'undefined';
const BuildWorkflow = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  const [saveError, setSaveError] = useState<string>();
  const newWorkflow = useNewWorkflow();
  const showModal = useShowModal();
  const clearNewWorkflow = useClearNewWorkflow();
  const isValid =
    newWorkflow.workflowName.trim().length > 2 && newWorkflow.stages.length;
  const startWithApproval = newWorkflow.stages[0]?.stageId === StageId.APPROVAL;
  const hasInvalidApprovals = newWorkflow.stages.find((s, index) => {
    if (index === 0 || s.stageId !== StageId.APPROVAL) return false;
    const previousStage = newWorkflow.stages[index - 1];
    return [StageId.APPROVAL, StageId.PROVIDER].includes(previousStage.stageId);
  });

  async function saveAndExit() {
    if (await save()) navigate(`/choose-form-type`);
  }

  useEffect(() => {
    if (!startWithApproval && !hasInvalidApprovals) setSaveError(undefined);
  }, [startWithApproval, hasInvalidApprovals, newWorkflow]);

  useEffect(() => {
    return () => clearNewWorkflow();
  }, []);

  async function save(): Promise<number> {
    if (startWithApproval) {
      setSaveError(
        'Custom Workflow cannot start with the training provider approval stage.',
      );
      return 0;
    }

    if (hasInvalidApprovals) {
      setSaveError(
        `Training Provider Approval can only be placed after Employer or Learner stages.`,
      );
      return 0;
    }

    const res = await fetchAPI({
      method: 'POST',
      body: newWorkflow,
      path: `Workflow`,
      responseType: 'json',
    });
    if (res.success) {
      return (res.body as { id: number }).id;
    }
    return 0;
  }

  async function saveAndContinue() {
    var workflowId = await save();
    if (workflowId) navigate(`/build-form/?workflowId=${workflowId}`);
  }

  return (
    <>
      <BuildWorkflowHeader>
        <h2>Create Form Workflow</h2>
        <Button
          disabled={!isValid}
          variant="secondary"
          dataQa="form-btn-save"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build workflow - save and exit',
            });
            showModal(SaveAndExit, {
              onCancel: () => navigate('/choose-form-type'),
              onSave: () => saveAndExit(),
            });
          }}
          iconRight={<IconAssessments responsive />}
        >
          Save and exit
        </Button>
        <Button
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build workflow - save and continue',
            });
            saveAndContinue();
          }}
          iconRight={<IconArrowRight responsive />}
          dataQa="form-btn-publish"
          disabled={!isValid}
        >
          Save and continue to create form
        </Button>
      </BuildWorkflowHeader>
      <GridFlex gutters={false} align="stretch">
        <LayoutSidebar small={4} gutters={false}>
          <CreateWorkflow />
        </LayoutSidebar>
        <LayoutContent small={8} gutters={false}>
          {saveError && (
            <Box padding="2rem">
              <Message error>{saveError}</Message>
            </Box>
          )}
          <BuildWorkflowContent />
        </LayoutContent>
      </GridFlex>
    </>
  );
};

export default BuildWorkflow;
