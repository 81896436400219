import React, { InputHTMLAttributes } from 'react';
import {
  LabelText,
  LabelWrapper,
  MandatoryIndicator,
  WordWrapBox,
} from '../../../layout/Layout.style';
import {
  InputCheckbox,
  InputCheckboxField,
  CheckboxInline,
} from './Checkbox.style';
import { FilterSubText } from '../../filter/FilterSubText.style';
const renderHTML = require('react-render-html');

export type ICheckbox = InputHTMLAttributes<HTMLInputElement> & {
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  id: string;
  invalid?: boolean;
  label?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  inline?: boolean;
  dataQa?: string;
  ellipsis?: boolean;
  subText?: string;
};

const Checkbox = ({
  checked = false,
  disabled = false,
  id,
  invalid = false,
  label,
  name,
  value,
  readOnly = false,
  onChange,
  onBlur,
  inline,
  dataQa,
  ellipsis = false,
  subText,
  ...inputAttributes
}: ICheckbox) => {
  const inner = (
    <InputCheckboxField>
      <InputCheckbox
        id={id}
        type="checkbox"
        checked={checked}
        name={name}
        data-qa={dataQa}
        value={value}
        disabled={disabled || readOnly}
        readOnly
        invalid={invalid}
        onChange={onChange}
        onBlur={onBlur}
        subText={subText}
      />
    </InputCheckboxField>
  );
  return inline ? (
    <CheckboxInline>
      {inner}
      <label
        className={ellipsis ? 'truncate' : undefined}
        htmlFor={id}
        data-qa={`form-lbl-${label?.split(' ')[0]}`}
      >
        <LabelWrapper>
          <LabelText>{renderHTML(label)}</LabelText>
          <MandatoryIndicator>
            {inputAttributes.required && '*'}
          </MandatoryIndicator>
        </LabelWrapper>
        {subText ? (
          <FilterSubText>
            <p>{subText}</p>
          </FilterSubText>
        ) : null}
      </label>
    </CheckboxInline>
  ) : (
    <CheckboxInline>
      {inner}
      <WordWrapBox>
        <label htmlFor={id} data-qa={`form-lbl-${label?.split(' ')[0]}`}>
          {renderHTML(label)}
          {subText ? (
            <div style={{ fontSize: 12 }}>
              <p>{subText}</p>
            </div>
          ) : null}
        </label>
      </WordWrapBox>
    </CheckboxInline>
  );
};

export default Checkbox;
