import React from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { IconBin } from '../../icons';

interface ISaveAndExitProps {
  onCancel: () => void;
  onSave: () => void;
}

const SaveAndExit = ({ onCancel, onSave }: ISaveAndExitProps) => {
  const closeModal = useCloseModal();
  return (
    <Stack stackMultiplier={2}>
      <h2>Exit Create Workflow</h2>
      <p>
        Workflows cannot be saved as drafts or edited. Please ensure completion
        before saving and exiting.
      </p>
      <ButtonRowMobile>
        <Button
          onClick={() => {
            onCancel();
            closeModal();
          }}
          variant="secondary"
          dataQa="saveAndExitModal-btn-cancel"
        >
          Exit without saving
        </Button>
        <Button
          onClick={() => {
            onSave();
            closeModal();
          }}
          variant="primary"
          dataQa="saveAndExitModal-btn-save"
        >
          Save and exit
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};

export default SaveAndExit;
