import React from 'react';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import AuthWrapper from '../../components/layout/AuthWrapper';
import BuildWorkflow from '../../components/organisms/build-workflow/BuildWorkflow';

const BuildWorkflowPage = () => {
  return (
    <AuthWrapper>
      <Head title="Build Form Workflow" />
      <Layout
        section="templates"
        breadcrumbs={[
          { link: '/list-forms', label: 'Form Templates' },
          { link: '/choose-form-type', label: 'Form Workflows' },
          { label: 'Create Form Workflow' },
        ]}
      >
        <BuildWorkflow />
      </Layout>
    </AuthWrapper>
  );
};

export default BuildWorkflowPage;
